import { Typography } from 'antd'

export default function Testing() {
  return (
    <div>
      <Typography>Testing Page</Typography>
      <div>Below is a test of virtual listing.</div>
    </div>
  )
}
