import {
  ArrowDownTrayIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  Cog8ToothIcon,
  HomeIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import CompassIcon from '../assets/icons/CompassIcon'
import UtilitiesIcon from '../assets/icons/UtilitiesIcon'

const nav_routes = [
  {
    path: '/home',
    navRoute: true,
    icon: <HomeIcon />,
    title: 'Home',
  },
  {
    path: '/accounts',
    navRoute: true,
    icon: <UserGroupIcon />,
    title: 'Accounts',
  },
  {
    path: '/clients',
    navRoute: true,
    icon: <ClipboardDocumentListIcon />,
    title: 'Clients',
  },
  {
    path: '/editor',
    navRoute: true,
    icon: <PencilSquareIcon />,
    title: "Editor"
  },
  {
    path: "/kompass",
    navRoute: true,
    icon: <CompassIcon />,
    title: "Kompass"
  },
  {
    path: '/export',
    navRoute: true,
    icon: <ArrowDownTrayIcon />,
    title: 'Exports',
  },
  {
    path: '/changelog',
    navRoute: true,
    icon: <ClockIcon />,
    title: 'Change Log',
  },
  {
    path: '/settings',
    navRoute: true,
    icon: <Cog8ToothIcon />,
    title: 'Settings',
  },
  {
    path: '/about',
    navRoute: true,
    icon: <InformationCircleIcon />,
    title: 'About',
  },
]

export default nav_routes

/*
  {
    path: '/services',
    navRoute: true,
    icon: <UtilitiesIcon />,
    title: 'Services',
  },
*/