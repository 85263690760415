import { Card, Typography } from '@material-tailwind/react'
import PictureIcon from '../assets/icons/PictureIcon'
import { Link } from 'react-router-dom'
import TableIcon from '../assets/icons/TableIcon'

export default function Services() {
  const services = [
    {
      title: 'Scorecards',
      icon: TableIcon,
      path: 'scorecards',
    },
    {
      title: 'Planogram Mapper',
      icon: PictureIcon,
      path: 'pog_mapper',
    },
  ]

  function ServiceCard(props: { path; icon; title }) {
    return (
      <Link to={props.path} replace className='p-4'>
        <Card placeholder={'.'} className="h-40 w-40 shadow-md border flex flex-col justify-center align-middle">
          <div className='w-full h-full flex flex-row align-middle justify-center'><div className='w-24 h-24'>{props.icon()}</div></div>
          <div className='flex justify-center px-2 py-4'>{props.title}</div>
        </Card>
      </Link>
    )
  }

  return (
    <div className="flex flex-col ">
      <Card placeholder="." className="flex flex-col h-full w-full rounded-lg">
        <div className="flex flex-col h-full w-full p-4">
          <Typography placeholder={'.'} variant="h3" className=" text-black">
            Misc. Services
          </Typography>
        </div>
        <hr></hr>
        <div className="flex flex-row flex-wrap w-full px-10">
          {services.map((config) => {
            return (
              <ServiceCard
                path={config.path}
                icon={config.icon}
                title={config.title}
              />
            )
          })}
        </div>
      </Card>
    </div>
  )
}
