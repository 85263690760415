import { Button, Card, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { db } from '../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import Loading from '../components/Loading'
import { Drawer, Input } from 'antd'

export default function Scorecards() {
  const NewConfigButton = () => {
    return <></>
  }

  const ViewConfig = () => {
    return (
      <div>
        {currentClientId === '' ? (
          <div>No Scorecard Selected.</div>
        ) : (
          <div className="p-2">{currentClientId}</div>
        )}
      </div>
    )
  }

  const ConfigsList = () => {
    return (
      <div className="h-fit p-2 w-full">
        {scorecardConfigs ? (
          <div className="w-full">
            {scorecardConfigs.length > 0 ? (
              <div className="w-full h-full flex flex-col">
                {scorecardConfigs.map((config) => {
                  return (
                    <div className="w-full">
                      {currentClientId === config['client_id'] ? (
                        <div
                          className="w-full h-10 flex flex-row bg-blue-200 rounded-md p-2"
                          onClick={() =>
                            setCurrentClientId('')
                          }
                        >
                          {config['client_id']
                            ? config['client_id']
                            : 'No Id Found.'}
                        </div>
                      ) : (
                        <div
                          className="w-full h-10 flex flex-row hover:bg-blue-100 rounded-md p-2"
                          onClick={() =>
                            setCurrentClientId(config['client_id'])
                          }
                        >
                          {config['client_id']
                            ? config['client_id']
                            : 'No Id Found.'}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : (
              <>No Results</>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    )
  }

  async function getScorecardConfigs() {
    const scorecard_configs = collection(db, 'scorecards')
    const unsub = onSnapshot(scorecard_configs, (snapshotSet) => {
      var scorecard_snapshots: Object[] = []
      snapshotSet.forEach((docSnap) => {
        if (docSnap.exists()) {
          scorecard_snapshots.push(docSnap.data())
        }
      })
      setScorecardConfigs(scorecard_snapshots)
    })
  }

  const [scorecardConfigs, setScorecardConfigs] = useState<
    Object[] | undefined
  >()
  const [createNewConfig, setCreateNewConfig] = useState(false)
  const [currentClientId, setCurrentClientId] = useState('')

  const [newScorecardDrawer, setNewScorecardDrawer] = useState(false)

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      getScorecardConfigs()
    }
  })
  return (
    <div className="flex flex-col">
      <Card placeholder="." className="flex flex-col h-fit w-full rounded-lg">
        <div className="flex flex-row h-full w-full p-4 justify-between">
          <Typography placeholder={'.'} variant="h3" className=" text-black">
            Scorecards
          </Typography>
          <Button placeholder={"."} className='p-2' onClick={()=> setNewScorecardDrawer(true)}>
            Add New Scorecard
          </Button>
        </div>
        <hr></hr>
        <div className="flex flex-row h-96">
          <div className="w-1/3 p-2 flex flex-col">
            <div className="font-bold text-black flex justify-center">
              <Input placeholder="Client Name" title="Search" />
            </div>
            <hr></hr>
            <div className="flex overflow-y-scroll">
              <ConfigsList />
            </div>
          </div>
          <div className="h-full border w-0.5"></div>
          <div className="w-2/3 p-2">
            <ViewConfig />
          </div>
        </div>
      </Card>

      <Drawer
        open={newScorecardDrawer}
        onClose={() => setNewScorecardDrawer(false)}
        placement="right"
        size={'large'}
      >
        <div className="p-10 flex flex-col h-full w-full justify-center">
          <div className="pb-4">
            <Typography placeholder={'.'} variant="h3">
              New Scorecard
            </Typography>
          </div>
          <hr></hr>
          <div className='h-full flex flex-col'>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
